// CLOSE MODAL PROFILES
window.htr_closeModal = function (modalContainerId) {
  let modalContainerElement = $('#' + modalContainerId);
  if(!modalContainerElement.length) {
    return 0;
  }
  modalContainerElement.empty();
  modalContainerElement.css('display', 'none');
}

